<script setup>
const { data, checkbox, darkTheme, labelClass } = defineProps({
  data: {
    type: Array,
    default: () => []
  },
  checkbox: {
    type: Boolean,
    default: false
  },
  darkTheme: {
    type: Boolean,
    default: true
  },
  labelClass: {
    type: String,
    default: ''
  }
})

const emit = defineEmits(['change'])
const selectedValues = defineModel({ type: [Array, String], default: [] })
const isModelString = computed(() => typeof selectedValues.value === 'string')
const isSelected = (id) => computed(() => 
  !isModelString.value && selectedValues.value.includes(id)
  || isModelString.value && selectedValues.value === id
)

const selectItem = (value) => emit('change', value)
</script>

<template>
  <div class="grid gap-2 pr-0.5 text-slate-900">
    <template v-for="(item, index) in data" :key="index">
      <button
        class="p-2 rounded-md hover:bg-gray-100"
        :class="{
          'bg-slate-900 hover:!bg-slate-900 text-white': darkTheme && isSelected(item.id).value && !checkbox,
          'bg-[#3e5cf5] hover:!bg-[#3e5cf5] text-white': !darkTheme && isSelected(item.id).value && !checkbox,
        }"
        @click="selectItem(item)"
      >
        <template v-if="checkbox">
          <div class="flex items-center gap-2.5" :class="labelClass">
            <input
              :id="`dropdown-${item.id}-${index}`"
              type="checkbox"
              :value="item.id"
              :checked="isSelected(item.id).value"
              class="size-4 accent-black cursor-pointer"
            >
            <span>
              {{ item.label }}
            </span>
          </div>  
        </template>
        <template v-else>
          <div class="text-left w-full" :class="labelClass">
            {{ item.label }}
          </div>
        </template>
      </button>
    </template>
  </div>
</template>
