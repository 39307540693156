<script setup>
import { ArrowDownBold } from '@element-plus/icons-vue'
import Spinner from '~/components/Spinner.vue'

const props = defineProps({
  title: {
    type: String,
    default: ''
  },
  isOptionsLoading: {
    type: Boolean,
    default: false
  },
  isSelected: {
    type: Boolean,
    default: false
  },
  labelClass: {
    type: String,
    default: ''
  },
  labelWrapperClass: {
    type: String,
    default: ''
  },
  triangleClass: {
    type: String,
    default: ''
  }
})


const dropdownContainerRef = ref(null)
const dropdownOpen = ref(false)
const isVisible = useElementVisibility(dropdownContainerRef)
const icon = computed(() => props.isOptionsLoading ? Spinner : ArrowDownBold)
const slots = useSlots()
const { isBreakpointView } = useScreen(768)

const toggleDropdown = () => {
  if (props.isOptionsLoading) return
  dropdownOpen.value = !dropdownOpen.value
}

const closeDropdown = () => {
  if (isVisible.value) {
    dropdownOpen.value = false
  }
}

useEventListener(document, 'click', closeDropdown)

defineExpose({
  isOpen: dropdownOpen,
  closeDropdown
})
</script>

<template>
  <div class="z-[30]">
    <div
      class="text-slate-900 flex cursor-pointer items-center gap-4 align-center select-none"
      :class="[
        {
          '!cursor-not-allowed': isOptionsLoading,
          '!text-[#3E5CF5]': isSelected
        },
        labelWrapperClass
      ]"
      @click="toggleDropdown"
    >
      <div class="whitespace-nowrap flex gap-2 items-center" :class="labelClass">
        <div v-if="slots.labelIcon">
          <slot name="labelIcon" />
        </div>
        <div>
          {{ title }}
        </div>
      </div>

      <slot name="icon">
        <component
          :is="icon"
          class="size-4 flex align-center transition-transform duration-100"
          :class="[{ 'transform rotate-180': dropdownOpen }, props.triangleClass]"
        />
      </slot>
    </div>
  
    <Teleport to="body">
      <Transition
        enter-active-class="transition-opacity ease-in"
        leave-active-class="transition-opacity ease-out"
        enter-from-class="opacity-0"
        enter-to-class="opacity-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div v-if="dropdownOpen" class="block md:hidden fixed left-0 top-0 h-full w-full bg-darkBlue/30 !z-[40]" />
      </Transition>
    </Teleport>

    <Teleport to="body" :disabled="!isBreakpointView">
      <Transition
        enter-active-class="transition-opacity ease-in"
        leave-active-class="transition-opacity ease-out"
        enter-from-class="opacity-0"
        enter-to-class="opacity-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div v-show="dropdownOpen" ref="dropdownContainerRef" class="fixed left-0 bottom-0 md:absolute md:bottom-auto md:left-auto md:-ml-2 mt-4 bg-white rounded-t-lg md:rounded-xl border !z-[1001] overflow-hidden w-full md:w-auto md:max-w-[397px]" @click.stop.self="closeDropdown">
          <div class="md:hidden border-b text-center text-2xl mx-6 pt-4 pb-3.5">
            {{ title }}
          </div>

          <div class="px-6 py-4 md:p-3 w-full" :class="{ 'pb-0': slots.buttons }">
            <div class="overflow-y-auto min-h-[20vh] md:min-h-0 max-h-[360px] scrollbar scrollbar--round w-full">
              <slot v-bind="{ closeDropdown, isButtons: slots.buttons }" />
            </div>
          </div>

          <template v-if="slots.buttons">
            <slot name="buttons" v-bind="{ isSelected, closeDropdown }" />
          </template>
        </div>
      </Transition>
    </Teleport>
  </div>
</template>